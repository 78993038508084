/**
* Template Name: Personal - v3.0.0
* Template URL: https://bootstrapmade.com/personal-free-resume-bootstrap-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
    font-family: "Open Sans", sans-serif;
    background-color: #040404;
    color: #fff;
    position: relative;
    background: transparent;
}

body::before {
    content: "";
    position: fixed;
    background: #040404 url("/public/images/temp_bg.jpg") top right no-repeat;
    background-size: cover;
    left: 0;
    right: 0;
    top: 0;
    height: 100vh;
    z-index: -1;
}

@media (min-width: 1024px) {
    body::before {
        background-attachment: fixed;
    }
}

a {
    color: #18d26e;
    text-decoration: none;
}

a:hover {
    color: #35e888;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Raleway", sans-serif;
}

/*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
#header {
    transition: ease-in-out 0.3s;
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    z-index: 997;
    overflow-y: auto;
}

#header * {
    transition: ease-in-out 0.3s;
}

#header h1 {
    font-size: 48px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
}

#header h1 a,
#header h1 a:hover {
    color: #fff;
    line-height: 1;
    display: inline-block;
}

#header h2 {
    font-size: 24px;
    margin-top: 20px;
    color: rgba(255, 255, 255, 0.8);
}

#header h2 span {
    color: #fff;
    border-bottom: 2px solid #18d26e;
    padding-bottom: 6px;
}

#header img {
    padding: 0;
    margin: 0;
}

#header .social-links {
    margin-top: 40px;
    display: flex;
}

#header .social-links a {
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    line-height: 1;
    margin-right: 8px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

#header .social-links a:hover {
    background: #18d26e;
}

@media (max-width: 992px) {
    #header h1 {
        font-size: 36px;
    }
    #header h2 {
        font-size: 20px;
        line-height: 30px;
    }
    #header .social-links {
        margin-top: 15px;
    }
    #header .container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu {
    margin-top: 35px;
}

.nav-menu ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
}

.nav-menu li + li {
    margin-left: 30px;
}

.nav-menu a {
    display: block;
    position: relative;
    color: rgba(255, 255, 255, 0.7);
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
}

.nav-menu a:before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -4px;
    left: 0;
    background-color: #18d26e;
    visibility: hidden;
    width: 0px;
    transition: all 0.3s ease-in-out 0s;
}

.nav-menu a:hover:before,
.nav-menu li:hover > a:before,
.nav-menu .active > a:before {
    visibility: visible;
    width: 25px;
}

.nav-menu a:hover,
.nav-menu .active > a,
.nav-menu li:hover > a {
    color: #fff;
    text-decoration: none;
}

/* Mobile Navigation */
.mobile-nav-toggle {
    position: fixed;
    right: 15px;
    top: 15px;
    z-index: 9998;
    border: 0;
    background: none;
    font-size: 24px;
    transition: all 0.4s;
    outline: none !important;
    line-height: 1;
    cursor: pointer;
    text-align: right;
}

.mobile-nav-toggle i {
    color: #fff;
}

.mobile-nav {
    position: fixed;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    z-index: 9999;
    overflow-y: auto;
    background: rgba(0, 0, 0, 0.7);
    transition: ease-in-out 0.2s;
    opacity: 0;
    visibility: hidden;
    border-radius: 10px;
    padding: 10px 0;
    border: 2px solid rgba(255, 255, 255, 0.12);
}

.mobile-nav * {
    margin: 0;
    padding: 0;
    list-style: none;
}

.mobile-nav a {
    display: block;
    position: relative;
    color: #fff;
    padding: 10px 20px;
    font-weight: 500;
    outline: none;
}

.mobile-nav a:hover,
.mobile-nav .active > a,
.mobile-nav li:hover > a {
    color: #18d26e;
    text-decoration: none;
}

.mobile-nav-overly {
    width: 100%;
    height: 100%;
    z-index: 9997;
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(9, 9, 9, 0.6);
    overflow: hidden;
    display: none;
    transition: ease-in-out 0.2s;
}

.mobile-nav.active {
    opacity: 1;
    visibility: visible;
}

/* Header Top */
#header.header-top {
    height: 80px;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.9);
}

#header.header-top .social-links,
#header.header-top h2 {
    display: none;
}

#header.header-top h1 {
    margin-right: auto;
    font-size: 36px;
}

#header.header-top .container {
    display: flex;
    align-items: center;
}

#header.header-top .nav-menu {
    margin: 0;
}

@media (max-width: 768px) {
    #header.header-top {
        height: 60px;
    }
    #header.header-top h1 {
        font-size: 26px;
    }
}

/*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
section {
    overflow: hidden;
    position: absolute;
    width: 100%;
    top: 140px;
    bottom: 100%;
    opacity: 0;
    transition: ease-in-out 0.4s;
    z-index: 2;
}

section.section-show {
    top: 100px;
    bottom: auto;
    opacity: 1;
    padding-bottom: 45px;
}

section .container {
    background: rgba(0, 0, 0, 0.9);
    padding: 30px;
}

@media (max-width: 768px) {
    section {
        top: 120px;
    }
    section.section-show {
        top: 80px;
    }
}

.section-title h2 {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    line-height: 1px;
    margin: 0 0 20px 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #aaaaaa;
    font-family: "Poppins", sans-serif;
}

.section-title h2::after {
    content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    background: #4ceb95;
    margin: 4px 10px;
}

.section-title p {
    margin: 0;
    margin: -15px 0 15px 0;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    color: #fff;
}

/*--------------------------------------------------------------
  # About
  --------------------------------------------------------------*/
.about-me .content h3 {
    font-weight: 700;
    font-size: 26px;
    color: #18d26e;
}

.about-me .content ul {
    list-style: none;
    padding: 0;
}

.about-me .content ul li {
    padding-bottom: 10px;
}

.about-me .content ul i {
    font-size: 20px;
    padding-right: 2px;
    color: #18d26e;
}

.about-me .content p:last-child {
    margin-bottom: 0;
}

.about-me .bx {
    vertical-align: middle;
    animation-duration: 0.8s;
}

/*--------------------------------------------------------------
  # Counts
  --------------------------------------------------------------*/
.counts {
    padding: 70px 0 60px;
}

.counts .count-box {
    padding: 30px 30px 25px 30px;
    width: 100%;
    position: relative;
    text-align: center;
    background: rgba(255, 255, 255, 0.08);
}

.counts .count-box i {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 24px;
    background: rgba(255, 255, 255, 0.1);
    padding: 12px;
    color: #18d26e;
    border-radius: 50px;
}

.counts .count-box span {
    font-size: 36px;
    display: block;
    font-weight: 600;
    color: #fff;
}

.counts .count-box p {
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-size: 14px;
}

/*--------------------------------------------------------------
  # Skills
  --------------------------------------------------------------*/
.skills .progress {
    height: 50px;
    display: block;
    background: none;
}

.skills .progress .skill {
    padding: 10px 0;
    margin: 0 0 6px 0;
    text-transform: uppercase;
    display: block;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #fff;
}

.skills .progress .skill .val {
    float: right;
    font-style: normal;
}

.skills .progress-bar-wrap {
    background: rgba(255, 255, 255, 0.15);
}

.skills .progress-bar {
    width: 1px;
    height: 10px;
    transition: 0.9s;
    background-color: #18d26e;
}

/*--------------------------------------------------------------
  # Interests
  --------------------------------------------------------------*/
.interests .icon-box {
    display: flex;
    align-items: center;
    padding: 20px;
    background: rgba(255, 255, 255, 0.08);
    transition: ease-in-out 0.3s;
}

.interests .icon-box i {
    font-size: 32px;
    padding-right: 10px;
    line-height: 1;
}

.interests .icon-box h3 {
    font-weight: 700;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-size: 16px;
    color: #fff;
    padding-left: 10px;
}

.interests .icon-box:hover {
    background: rgba(255, 255, 255, 0.12);
}

/*--------------------------------------------------------------
  # Testimonials
  --------------------------------------------------------------*/
.testimonials .testimonial-item {
    box-sizing: content-box;
    min-height: 320px;
}

.testimonials .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 50%;
    margin: -40px 0 0 40px;
    position: relative;
    z-index: 2;
    border: 6px solid rgba(255, 255, 255, 0.12);
}

.testimonials .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 45px;
    color: #fff;
}

.testimonials .testimonial-item h4 {
    font-size: 14px;
    color: #999;
    margin: 0 0 0 45px;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
    color: rgba(255, 255, 255, 0.25);
    font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
}

.testimonials .testimonial-item p {
    font-style: italic;
    margin: 0 15px 0 15px;
    padding: 20px 20px 60px 20px;
    background: rgba(255, 255, 255, 0.1);
    position: relative;
    border-radius: 6px;
    position: relative;
    z-index: 1;
}

.testimonials .owl-nav,
.testimonials .owl-dots {
    margin-top: 5px;
    text-align: center;
}

.testimonials .owl-dot {
    display: inline-block;
    margin: 0 5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.3) !important;
}

.testimonials .owl-dot.active {
    background-color: #18d26e !important;
}

@media (max-width: 767px) {
    .testimonials {
        margin: 30px 10px;
    }
}

/*--------------------------------------------------------------
  # Resume
  --------------------------------------------------------------*/
.resume .resume-title {
    font-size: 26px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #fff;
}

.resume .resume-item {
    padding: 0 0 20px 20px;
    margin-top: -2px;
    border-left: 2px solid rgba(255, 255, 255, 0.2);
    position: relative;
}

.resume .resume-item h4 {
    line-height: 18px;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    color: #18d26e;
    margin-bottom: 10px;
}

.resume .resume-item h5 {
    font-size: 16px;
    background: rgba(255, 255, 255, 0.15);
    padding: 5px 15px;
    display: inline-block;
    font-weight: 600;
    margin-bottom: 10px;
}

.resume .resume-item ul {
    padding-left: 20px;
}

.resume .resume-item ul li {
    padding-bottom: 10px;
}

.resume .resume-item:last-child {
    padding-bottom: 0;
}

.resume .resume-item::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    left: -9px;
    top: 0;
    background: #18d26e;
    border: 2px solid #18d26e;
}

/*--------------------------------------------------------------
  # Services
  --------------------------------------------------------------*/
.services .icon-box {
    text-align: center;
    background: rgba(204, 204, 204, 0.1);
    padding: 80px 20px;
    transition: all ease-in-out 0.3s;
}

.services .icon-box .icon {
    margin: 0 auto;
    width: 64px;
    height: 64px;
    background: #18d26e;
    border-radius: 5px;
    transition: all 0.3s ease-out 0s;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transform-style: preserve-3d;
}

.services .icon-box .icon i {
    color: #fff;
    font-size: 28px;
}

.services .icon-box .icon::before {
    position: absolute;
    content: "";
    left: -8px;
    top: -8px;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 5px;
    transition: all 0.3s ease-out 0s;
    transform: translateZ(-1px);
}

.services .icon-box h4 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
}

.services .icon-box h4 a {
    color: #fff;
}

.services .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}

.services .icon-box:hover {
    background: #18d26e;
    border-color: #18d26e;
}

.services .icon-box:hover .icon {
    background: #fff;
}

.services .icon-box:hover .icon i {
    color: #18d26e;
}

.services .icon-box:hover .icon::before {
    background: #35e888;
}

.services .icon-box:hover h4 a,
.services .icon-box:hover p {
    color: #fff;
}

/*--------------------------------------------------------------
  # Portfolio
  --------------------------------------------------------------*/
.portfolio .portfolio-item {
    margin-bottom: 30px;
}

.portfolio #portfolio-flters {
    padding: 0;
    margin: 0 auto 15px auto;
    list-style: none;
    text-align: center;
    border-radius: 50px;
    padding: 2px 15px;
}

.portfolio #portfolio-flters li {
    cursor: pointer;
    display: inline-block;
    padding: 8px 16px 10px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    color: #fff;
    background: rgba(255, 255, 255, 0.1);
    margin: 0 3px 10px 3px;
    transition: all 0.3s ease-in-out;
    border-radius: 4px;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
    background: #18d26e;
}

.portfolio #portfolio-flters li:last-child {
    margin-right: 0;
}

.portfolio .portfolio-wrap {
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background: rgba(0, 0, 0, 0.6);
}

.portfolio .portfolio-wrap::before {
    content: "";
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    left: 30px;
    right: 30px;
    top: 30px;
    bottom: 30px;
    transition: all ease-in-out 0.3s;
    z-index: 2;
    opacity: 0;
}

.portfolio .portfolio-wrap .portfolio-info {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    z-index: 3;
    transition: all ease-in-out 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.portfolio .portfolio-wrap .portfolio-info::before {
    display: block;
    content: "";
    width: 48px;
    height: 48px;
    position: absolute;
    top: 35px;
    left: 35px;
    border-top: 3px solid #fff;
    border-left: 3px solid #fff;
    transition: all 0.5s ease 0s;
    z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info::after {
    display: block;
    content: "";
    width: 48px;
    height: 48px;
    position: absolute;
    bottom: 35px;
    right: 35px;
    border-bottom: 3px solid #fff;
    border-right: 3px solid #fff;
    transition: all 0.5s ease 0s;
    z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
    color: #ffffff;
    font-size: 14px;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
}

.portfolio .portfolio-wrap .portfolio-links {
    text-align: center;
    z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links a {
    color: #fff;
    margin: 0 2px;
    font-size: 28px;
    display: inline-block;
    transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
    color: #63eda3;
}

.portfolio .portfolio-wrap:hover::before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info {
    opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info::before {
    top: 15px;
    left: 15px;
}

.portfolio .portfolio-wrap:hover .portfolio-info::after {
    bottom: 15px;
    right: 15px;
}

/*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
.contact .info-box {
    color: #444444;
    padding: 20px;
    width: 100%;
    background: rgba(255, 255, 255, 0.08);
}

.contact .info-box i.bx {
    font-size: 24px;
    color: #18d26e;
    border-radius: 50%;
    padding: 14px;
    float: left;
    background: rgba(255, 255, 255, 0.1);
}

.contact .info-box h3 {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.5);
    font-weight: 700;
    margin: 10px 0 8px 68px;
}

.contact .info-box p {
    padding: 0;
    color: #fff;
    line-height: 24px;
    font-size: 14px;
    margin: 0 0 0 68px;
}

.contact .info-box .social-links {
    margin: 5px 0 0 68px;
    display: flex;
}

.contact .info-box .social-links a {
    font-size: 18px;
    display: inline-block;
    color: #fff;
    line-height: 1;
    margin-right: 12px;
    transition: 0.3s;
}

.contact .info-box .social-links a:hover {
    color: #18d26e;
}

.contact .php-email-form {
    padding: 30px;
    background: rgba(255, 255, 255, 0.08);
}

.contact .php-email-form .validate {
    display: none;
    color: red;
    margin: 0 0 15px 0;
    font-weight: 400;
    font-size: 13px;
}

.contact .php-email-form .error-message {
    display: none;
    background: rgba(255, 255, 255, 0.08);
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .error-message br + br {
    margin-top: 25px;
}

.contact .php-email-form .sent-message {
    display: none;
    background: rgba(255, 255, 255, 0.08);
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .loading {
    display: none;
    background: rgba(255, 255, 255, 0.08);
    text-align: center;
    padding: 15px;
}

.contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    background: rgba(255, 255, 255, 0.08);
    border: 0;
    transition: 0.3s;
    color: #fff;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
    background-color: rgba(255, 255, 255, 0.11);
}

.contact .php-email-form input::-webkit-input-placeholder,
.contact .php-email-form textarea::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.3);
    opacity: 1;
}

.contact .php-email-form input::-moz-placeholder,
.contact .php-email-form textarea::-moz-placeholder {
    color: rgba(255, 255, 255, 0.3);
    opacity: 1;
}

.contact .php-email-form input:-ms-input-placeholder,
.contact .php-email-form textarea:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.3);
    opacity: 1;
}

.contact .php-email-form input::-ms-input-placeholder,
.contact .php-email-form textarea::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.3);
    opacity: 1;
}

.contact .php-email-form input::placeholder,
.contact .php-email-form textarea::placeholder {
    color: rgba(255, 255, 255, 0.3);
    opacity: 1;
}

.contact .php-email-form input {
    padding: 10px 15px;
}

.contact .php-email-form textarea {
    padding: 12px 15px;
}

.contact .php-email-form button[type="submit"] {
    background: #18d26e;
    border: 0;
    padding: 10px 30px;
    color: #fff;
    transition: 0.4s;
    border-radius: 4px;
}

.contact .php-email-form button[type="submit"]:hover {
    background: #15bb62;
}

@-webkit-keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/*--------------------------------------------------------------
  # Portfolio Details
  --------------------------------------------------------------*/
.portfolio-details {
    padding-top: 40px;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow-y: auto;
}

.portfolio-details .container {
    padding-top: 20px;
    padding-bottom: 40px;
}

.portfolio-details .portfolio-title {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
}

.portfolio-details .portfolio-details-carousel .owl-nav,
.portfolio-details .portfolio-details-carousel .owl-dots {
    margin-top: 5px;
    text-align: center;
}

.portfolio-details .portfolio-details-carousel .owl-dot {
    display: inline-block;
    margin: 0 5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.3) !important;
}

.portfolio-details .portfolio-details-carousel .owl-dot.active {
    background-color: #18d26e !important;
}

.portfolio-details .portfolio-info {
    padding-top: 45px;
}

.portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 20px;
}

.portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
    margin-top: 10px;
}

.portfolio-details .portfolio-info p {
    font-size: 15px;
    padding: 15px 0 0 0;
}

@media (max-width: 992px) {
    .portfolio-details .portfolio-info {
        padding-top: 20px;
    }
}

/*--------------------------------------------------------------
  # Credits
  --------------------------------------------------------------*/
.credits {
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 15px;
    text-align: right;
    font-size: 13px;
    color: #fff;
    z-index: 999999;
}

@media (max-width: 992px) {
    .credits {
        text-align: center;
        padding: 10px;
        background: rgba(0, 0, 0, 0.8);
    }
}

.credits a {
    color: #18d26e;
    transition: 0.3s;
}

.credits a:hover {
    color: #fff;
}


